
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import EditableCell from '@/component/editable-cell.vue';
import {
  getHomeWareList,
  initHomeWareData,
  HomeWareData,
  changeStatus,
  editSortValue,
} from '@/api/operation/home_ware';
import { PaginationData } from '@/api/operation/customer';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
@Component({
  name: 'HomeWare',
  components: {
    EditableCell,
  },
})
export default class HomeWare extends Vue {
  setGlobalFunction = useGlobalFunction().setGlobalFunction;
  home_ware_data: PaginationData<HomeWareData> = initHomeWareData();
  is_loading: boolean = false;

  @changeLoading(['is_loading'])
  async handleSortChange(id: number, sort: number) {
    if (!/^[0-9]+$/.test(sort + '')) {
      this.$message.error('排序值须为>=0的整数值！');
      return;
    }
    /* if (sort === 0) {
      this.$message.error('排序值不能为0！');
      return;
    } */
    const res: any = await editSortValue(id, sort);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.$message.success('更新排序值成功');
    await this.getHomeWareList();
  }

  // 获取首页商品
  @changeLoading(['is_loading'])
  async getHomeWareList(current?: number) {
    const res: any = await getHomeWareList(current);
    this.home_ware_data = initHomeWareData();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.home_ware_data = initHomeWareData(res.data);
  }

  // 编辑商品模块
  editHomeWare(item: any) {
    this.$router.push(`/operation/add-ware-module?id=${item.id}`);
  }

  // 切换上下架状态
  @changeLoading(['is_loading'])
  async changeStatus(id: number, status: number) {
    const res: any = await changeStatus(id, status);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.$message.success(status === 1 ? '上架成功' : '下架成功');
    await this.getHomeWareList();
  }

  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    await this.getHomeWareList(data.current);
  }
  paginationOption(data: any) {
    return {
      pageSize: data.limit || 0,
      total: data.total || 0,
      current: data.current || 1,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  @changeLoading(['is_loading'])
  async created() {
    await this.getHomeWareList();
    await this.setGlobalFunction({ getHomeWareLis: this.getHomeWareList })
  }
}
