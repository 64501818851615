import { request as axios } from '@/util/request';
import { PaginationData, WareData } from '@/api/operation/customer';
const getUrl = require('baimahu/url-with-param');

const API = {
  add_home_ware: '/boss/operation/activity',
  ware_list: '/boss/operation/activity',
  change_stauts: '/boss/operation/activity/status',
  edit_sort_value: '/boss/operation/activity/sort',
  home_ware_module_ware: '/boss/operation/activity/product',
  config_product:'/boss/operation/activity/product/configured'
}

// 首页商品列表数据接口
export interface HomeWareData {
  create_time: number;
  id: number;
  product_count: number;
  sort: number;
  status: number;
  subtitle: string;
  title: string;
  type: number;
  update_time: number;
}
// 初始化首页商品列表数据
export function initHomeWareData(remote?: PaginationData<HomeWareData>): PaginationData<HomeWareData> {
  remote = remote || {} as PaginationData<HomeWareData>;
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  }
}
// 获取首页商品列表
export async function getHomeWareList(current: number = 1) {
  return axios.get(`${API.ware_list}?current=${current}`);
}

// 初始化商品列表
export function initWareList(remote?: PaginationData<WareData>): PaginationData<WareData> {
  remote = remote || {} as PaginationData<WareData>;
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  }
}

// 请求参数数据接口
interface SendData {
  title: string;
  subtitle: string;
  product: ProductData[];
}
// 商品数据接口
interface ProductData {
  product_id: number;
  sort: number;
}
// 添加商品
export async function addHomeWare(send_data: SendData) {
  return await axios.post(API.add_home_ware, {
    ...send_data
  });
}

// 切换上下架状态
export async function changeStatus(id: number, status: number) {
  return await axios.put(`${API.change_stauts}/${id}`, {
    status
  });
}

// 编辑排序值
export async function editSortValue(id: number, sort: number) {
  return await axios.put(`${API.edit_sort_value}/${id}`, {
    sort,
  });
}
//判断商品是否已经选中
export async function configProduct(activity_id: number,product_id: string){
  const url = getUrl(API.config_product, {
    activity_id,
    product_id
  });
  return await axios.get(url);
}

// 获取单个活动的详情
export async function getHomeWareDetail(id: number) {
  return await axios.get(`${API.add_home_ware}/${id}`);
}


// 格式化单个活动商品数据
export function initSignleHomeWare(remote?: any): any {
  remote = remote || {};
  return {
    current: remote.current || 0,
    detail: remote.detail.map((item: any) => {
      item.product.sort = item.sort;
      return {
        ...item.product,
        edit_id: item.id
      }
    }) || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  }
}
// 获取单个活动的商品详情
export async function getSignleHomeWare(activity_id: number, current: number = 1,limit: number =10) {
  const url = getUrl(API.home_ware_module_ware, {
    current,
    activity_id,
    limit
  });
  return await axios.get(url);
}
export async function deleteWare(send_data: any){
  return await axios.delete(`${API.home_ware_module_ware}/1`,{data:{...send_data}})
} 
export async function editHomeWare(send_data: any){
  return await axios.post(API.home_ware_module_ware,{...send_data});
}
// 编辑活动
export async function submiteditHomeWare(id: number, send_data: any) {
  return await axios.put(`${API.add_home_ware}/${id}`, {
    ...send_data
  });
}